import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'react-loader-spinner';
import {toast} from 'react-toastify';

import SpamtestAutoFixTaskService from '../../services/spamtestAutofixTask.service';
import SpamCheckerService from '../../services/spamChecker.service';

import SpamtestAutofixAiDetails from './spamtestAutofixAiDetails.js';


class SpamcheckerAutofixAi extends React.Component {

	constructor(props) {
		super(props);
		this.state = {error: false, spamtestAutofixTask: null, inapp_messages: [], loading: true, details: {}, openKeys: {}};

    this.runSpamcheckAi = this.runSpamcheckAi.bind(this);
		this.reloadSpamcheckerAi = this.reloadSpamcheckerAi.bind(this);
		this.loadSpamcheck = this.loadSpamcheck.bind(this);
	}

  componentWillMount() {
    SpamtestAutoFixTaskService.get(this.props.match.params.test_id).then((response) => {
      if(response.body){
        this.setState({spamtestAutofixTask: response.body, loading: false})
      }else{
        this.setState({spamtestAutofixTask: null, loading: false})
      }

			if(response.body){
				if(!response.body.completed){
					setTimeout(
						() => this.reloadSpamcheckerAi(),
						8000
					);
				}
			}

    })
    .catch((error) => {
      console.log(error);
      this.setState({loading: false});
      toast.error('Oops. An error occured.');
    });
  }

	reloadSpamcheckerAi(){
		if(this.state.spamtestAutofixTask.completed){
			// Dont refresh if the task is already completed
			return;
		}
		SpamtestAutoFixTaskService.get(this.props.match.params.test_id).then((response) => {
			if(response.body){
				this.setState({spamtestAutofixTask: response.body, loading: false})
			}else{
				this.setState({spamtestAutofixTask: null, loading: false})
			}

			if (!response.body){
				setTimeout(
					() => this.reloadSpamcheckerAi(),
					10000
				);
			}else{
				if(!response.body.completed){
					setTimeout(
						() => this.reloadSpamcheckerAi(),
						10000
					);
				}
			}
		})
		.catch((error) => {
			this.setState({loading: false});
			toast.error('Oops. An error occured.');
		});
	}

  runSpamcheckAi(){
    SpamtestAutoFixTaskService.create(this.props.match.params.test_id).then((response) => {
			this.setState({spamtestAutofixTask: response.body, loading: false});
      //Launch reloading
			this.reloadSpamcheckerAi();
    })
    .catch((error) => {
      console.log(error);
      this.setState({loading: false});
      toast.error('Oops. An error occured.');
    });
  }

	loadSpamcheck(spamcheckInternalId, key){
		let openKeys = this.state.openKeys;
		if(openKeys[key]){
			openKeys[key] = false;
			this.setState({openDetails: openKeys});
			return;
		}
		openKeys[key] = true;
		this.setState({openDetails: openKeys})
		SpamCheckerService.getAutofix(spamcheckInternalId).then((response) => {
			let a = {}
			a[key] = response.body;
			this.setState({details: a});
		})
		.catch((error) => {
			toast.error('Oops, we have an issue, try again');
			this.setState({loadNextTest: false});
		});
	}

	componentWillReceiveProps(nextProps) {
    this.loadNextSteps(nextProps);
	}

	handleCloseModal(){
		this.setState({showNextStepModal: null})
	}

	render() {
    if(this.state.loading){
      return(
        <div className="card text-center">
          <div className="loader">
            <Loader
             type="Oval"
             color="#b7b7b7"
             height="100"
             width="100"
            />
          </div>
        </div>
      )
    }

    if(!this.state.spamtestAutofixTask && !this.state.loading){
      return (
        <div className="card upgrade-spamchecker run-autofix-ai">
            <div className="col-md-12 text-left">
              <h2>Our AI can help you find out why this email ends up in spam.</h2>
              <p>Since {this.props.currentTest.account.email} has a reputation score of {this.props.currentTest.account.score}, this test landing in spam on professional inboxes is likely due to the content of this email. Let MailReach try to improve your deliverability by running multiple tests from your mailbox. This is a Beta feature. </p>
              <p><button onClick={this.runSpamcheckAi} className="btn btn-primary green-bck">Run MailReach AI</button></p>
            </div>
            <div className="clearfix"></div>
          </div>
      )
    }
		return (
      <div>
        {!this.state.spamtestAutofixTask.spamtest_neutral_test_completed &&
  			   <div className="card card-autofix-progress col-md-12">
					 		<div className="txt pull-left col-md-10 text-left">
              	Testing a 100% harmless email to confirm that the content is the cause...
							</div>

							<Tooltip title='We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍.' placement="bottom">
								<div className="score running pull-right pulse">
									Running
								</div>
							</Tooltip>
           </div>
         }

         {this.state.spamtestAutofixTask.spamtest_neutral_test_completed &&
					 <>
						 {this.state.spamtestAutofixTask.spamtest_neutral_test_score >= 8 &&
							 <div className="card card-autofix-progress col-md-12">
									<div className="txt pull-left col-md-10 text-left">
										MailReach tested a 100% harmless content from your mailbox and got a better score! That confirms there’s at least one element in your content that causes spam.
									</div>

									<Tooltip title="Well done 🙌" placement="bottom">
										<span className="completed-btn-ai pull-right done green-bck" onClick={() => this.loadSpamcheck(this.state.spamtestAutofixTask.spamtest_neutral_test_id, 'spamtestAutofixNeutralDetails')}>
											Done &nbsp;
											{!this.state.openKeys.spamtestAutofixNeutralDetails &&
												<i class="fa-sharp-duotone fa-solid fa-chevron-left"></i>
											}
											{this.state.openKeys.spamtestAutofixNeutralDetails &&
												<i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
											}
										</span>
									</Tooltip>

									{this.state.openKeys.spamtestAutofixNeutralDetails &&
										<SpamtestAutofixAiDetails spamtest_initialy_marked_as_spam_on={this.state.spamtestAutofixTask.spamtest_initialy_marked_as_spam_on} details={this.state.details.spamtestAutofixNeutralDetails} />
									}
							 </div>
						 }

						 {this.state.spamtestAutofixTask.spamtest_neutral_test_score < 8 &&
							<div className="card card-autofix-progress col-md-12">
								 <div className="txt pull-left col-md-10 text-left">
									 MailReach tested a 100% harmless content from your mailbox and it still didn’t reach the inbox. Contact support so we can check 🙂
								 </div>

									<span className="completed-btn-ai pull-right done red-bck" onClick={() => this.loadSpamcheck(this.state.spamtestAutofixTask.spamtest_neutral_test_id, 'spamtestAutofixNeutralDetails')}>
									 	Failed &nbsp;
										{!this.state.openKeys.spamtestAutofixNeutralDetails &&
											<i class="fa-sharp-duotone fa-solid fa-chevron-left"></i>
										}
										{this.state.openKeys.spamtestAutofixNeutralDetails &&
											<i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
										}
									</span>

								 {this.state.openKeys.spamtestAutofixNeutralDetails &&
									 <SpamtestAutofixAiDetails spamtest_initialy_marked_as_spam_on={this.state.spamtestAutofixTask.spamtest_initialy_marked_as_spam_on} details={this.state.details.spamtestAutofixNeutralDetails} />
								 }

							</div>
						}
					 </>
          }

					{(!this.state.spamtestAutofixTask.spamtest_without_links_test_completed && this.state.spamtestAutofixTask.spamtest_without_links_test_id) &&
						 <div className="card card-autofix-progress col-md-12">
								<div className="txt pull-left col-md-10 text-left">
									Now testing new versions of your content without potentially spammy elements...
								</div>

								<Tooltip title='We wait up to 6 minutes for the mailboxes to receive your email before displaying the score 🔍.' placement="bottom">
									<div className="score running pull-right pulse">
										Running
									</div>
								</Tooltip>
						 </div>
					 }

					 {this.state.spamtestAutofixTask.spamtest_without_links_test_completed &&
						 <>
							 {this.state.spamtestAutofixTask.spamtest_without_links_test_score >= 8 &&
								 <div className="card card-autofix-progress col-md-12">
										<div className="txt pull-left col-md-10 text-left">
											MailReach tested a 100% harmless content from your mailbox and got a better score! That confirms there’s at least one element in your content that causes spam.
										</div>

											<span className="completed-btn-ai pull-right done green-bck" onClick={() => this.loadSpamcheck(this.state.spamtestAutofixTask.spamtest_neutral_test_id, 'spamtestAutofixNeutralDetails')}>
												Done &nbsp;
												{!this.state.openKeys.spamtestAutofixNeutralDetails &&
													<i class="fa-sharp-duotone fa-solid fa-chevron-left"></i>
												}
												{this.state.openKeys.spamtestAutofixNeutralDetails &&
													<i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
												}
											</span>


										{this.state.openKeys.spamtestAutofixNeutralDetails &&
											<SpamtestAutofixAiDetails spamtest_initialy_marked_as_spam_on={this.state.spamtestAutofixTask.spamtest_initialy_marked_as_spam_on} details={this.state.details.spamtestAutofixNeutralDetails} />
										}
								 </div>
							 }

							 {this.state.spamtestAutofixTask.spamtest_without_links_score < 8 &&
								<div className="card card-autofix-progress col-md-12">
									 <div className="txt pull-left col-md-10 text-left">
										 Step 2: MailReach tested your initial email, but this time, by removing all the most common spammy elements (links, tracking, signature, attachments, etc)
									 </div>

									 <Tooltip title="Well done 🙌" placement="bottom">
										<span className="completed-btn-ai pull-right done red-bck" onClick={() => this.loadSpamcheck(this.state.spamtestAutofixTask.spamtest_neutral_test_id, 'spamtestAutofixNeutralDetails')}>
											Failed &nbsp;
											{!this.state.openKeys.spamtestAutofixNeutralDetails &&
												<i class="fa-sharp-duotone fa-solid fa-chevron-left"></i>
											}
											{this.state.openKeys.spamtestAutofixNeutralDetails &&
												<i class="fa-sharp-duotone fa-solid fa-chevron-down"></i>
											}
										</span>
									 </Tooltip>

									 {this.state.openKeys.spamtestAutofixNeutralDetails &&
										 <SpamtestAutofixAiDetails spamtest_initialy_marked_as_spam_on={this.state.spamtestAutofixTask.spamtest_initialy_marked_as_spam_on} details={this.state.details.spamtestAutofixNeutralDetails} />
									 }

								</div>
							}
						 </>
						}
      </div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpamcheckerAutofixAi));
