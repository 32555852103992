import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { Button, Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';

class SpamtestAutofixAiDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {openShowTemplateModal: false, messageContentPreview: 'content_html', headersToShow: []};
	}


	componentWillReceiveProps(nextProps) {
	}

  getInboxTypelabel(email){
    let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
    let domain = email.split('@')[1];
    if(freeMails.includes(domain)){
      return '<span class="inbox-type-label perso">Personal</span>'
    }else{
      return '<span class="inbox-type-label pro">Professional</span>'
    }
  }

  getInboxType(email){
    let freeMails = ['gmail.com', 'outlook.com', 'outlook.fr', 'hotmail.com', 'yahoo.com'];
    let domain = email.split('@')[1];
    if(freeMails.includes(domain)){
      return 'perso'
    }else{
      return 'pro'
    }
  }

	openHeadersModal(log){
		if(Array.isArray(log.raw_headers)){
			this.setState({openShowHeadersModal: true, headersToShow: log.raw_headers});
		}
	}

	render() {
    return(
      <div className="col-md-12">
        {!this.props.details &&
          <div className="loader">
            <Loader
             type="Oval"
             color="#b7b7b7"
             height="100"
             width="100"
            />
          </div>
        }
        {this.props.details &&
          <div className="spamcheck-autofix-details">
            <h2 className="second-title">
              Where it landed
            </h2>

            <div className="see-email cursor-pointer" onClick={() => this.setState({openShowTemplateModal: true})}>See the email</div>

            <>
              {(this.props.details.results.length == 0 && !this.state.loading) &&
                <div className="empty text-center">
                  <img src="/images/instat_analysis.svg" />
                  <p>No results at the moment.</p>
                </div>
              }
              <table class="table table-results">
                <tbody>
                {this.props.details.results.map((log, index) => {
                  if(log.hidden){
                    return;
                  }
	                 if(!this.props.spamtest_initialy_marked_as_spam_on.map(a => a.email).includes(log.email)){
	                   return;
	                 }
                  return (
                    <tr>
                      <td>
                        <div>
                          {log.provider == 'custom' &&
                             <img className="avatar pull-left" src={'/images/custom.svg'} />
                          }
                          {log.provider != 'custom' &&
                             <img className="avatar pull-left" src={'/images/logo_' + log.provider + '.png'} />
                          }
                        </div>
                        <span className="hide-responsive cursor-pointer" onClick={() => this.openHeadersModal(log)}>{log.email}</span><br />
                        <span className="account-desc hidden-mobile">{log.desc}</span>
                      </td>
                      <td className="inbox-type">
                        <span className={"providerbadge hidden-mobile " + log.provider}>
                          {log.provider == 'gmail' &&
                            <>Google</>
                          }
                          {log.provider == 'outlook' &&
                            <>Microsoft</>
                          }
                          {!['gmail','outlook'].includes(log.provider) &&
                            <>{log.provider}</>
                          }
                        </span>

                        <div dangerouslySetInnerHTML={{__html: this.getInboxTypelabel(log.email)}} />
                      </td>
                      <td className="hour hidden-mobile">
                      <Moment fromNow>{this.props.details.created_at}</Moment>
                      </td>
                      <td>
                        {(!log.received_in && !this.props.details.completed) &&
                          <span className={"badge pending"}>Pending</span>
                        }
                        {(!log.received_in && this.props.details.completed) &&
                          <Tooltip title="Missing means MailReach doesn't find your email in this mailbox. It can mean your email has not landed yet OR it can mean the mailbox / provider didn't let you enter. In the latter, that's worst than spam." placement="bottom">
                            <span className={"badge missing-badge-color"}>Missing</span>
                          </Tooltip>
                        }
                        {log.received_in &&
                          <>
                            {log.received_in == 'INBOX' &&
                              <Tooltip title="Well done 🙌" placement="bottom">
                                <span className={"badge " + log.received_in}>{log.received_in.toLowerCase()}</span>
                              </Tooltip>
                            }
                            {(log.received_in == 'SPAM' && this.getInboxType(log.email) == 'perso')  &&
                              <Tooltip title="Not good BUT the question is : do you really target personal inboxes? Because if not, it's not relevant to take it into account. Spam filters work differently between Professional and Personal inboxes." placement="bottom">
                                <span className={"badge " + log.received_in}>{log.received_in.toLowerCase()}</span>
                              </Tooltip>
                            }
                            {(log.received_in == 'SPAM' && this.getInboxType(log.email) == 'pro')  &&
                              <Tooltip title="Not good BUT the question is : do you really target professional inboxes? Because if not, it's not relevant to take it into account. Spam filters work differently between Professional and Personal inboxes." placement="bottom">
                                <span className={"badge " + log.received_in}>{log.received_in.toLowerCase()}</span>
                              </Tooltip>
                            }
                            {!['INBOX', 'SPAM', 'MISSING'].includes(log.received_in) &&
                              <Tooltip title="Better than spam, but not the best 😐" placement="bottom">
                                <span className="badge categorie">{log.received_in.toLowerCase()}</span>
                              </Tooltip>
                            }
                          </>
                        }
                      </td>
                    </tr>
                  )})}
                </tbody>
              </table>
            </>
          </div>
        }

				{this.props.details &&
					<>
					<Modal className="spam-results-modal message-design" show={this.state.openShowTemplateModal} onHide={() => this.setState({openShowTemplateModal: false})}>
						<Modal.Body>
							<>
								<h1 className="text-center">{this.props.details.first_message_content.subject}</h1>
								<div className='from'>
									<b>{this.props.details.first_message_content.from_name}</b> {'<' + this.props.details.first_message_content.from_email + '>'}

									<ul className='format-selector pull-right'>
										<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_html' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_html'})}>HTML</li>
										<li className={"btn btn-secondary" + (this.state.messageContentPreview == 'content_text' ? ' selected' : '')} onClick={() => this.setState({messageContentPreview: 'content_text'})}>TEXT</li>
									</ul>
								</div>
								<div className="txt">
								<div
		dangerouslySetInnerHTML={{__html: this.props.details.first_message_content[this.state.messageContentPreview]}}
		/>
								</div>
								<div className="footer text-center">
									<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowTemplateModal: false})}>Close</button>
								</div>
							</>
						</Modal.Body>
					</Modal>

					<Modal className="spam-results-modal message-design" show={this.state.openShowHeadersModal} onHide={() => this.setState({openShowHeadersModal: false})}>
						<Modal.Body>
							<>
								<h1 className="text-center">Message Raw Headers</h1>
								<div className='headers'>
									<table>
										<tbody>
											{this.state.headersToShow.map((header, index) => {
												return (
													<tr>
														<td>{header.name} :</td>
														<td className="break-word">{header.unparsed_value}</td>
													</tr>
												)})}

										</tbody>
									</table>
								</div>
								<div className="footer text-center">
									<button type="button" className="btn btn-secondary" onClick={() => this.setState({openShowHeadersModal: false})}>Close</button>
								</div>
							</>
						</Modal.Body>
					</Modal>


					</>
				}

      </div>
    )
  }

}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpamtestAutofixAiDetails));
