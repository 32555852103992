const request = require('superagent');

// Todo, rename as test folder
class SpamCheckerService {

	static create(pool = nil) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}spam_tests${pool ? `?seedlist=${pool}` : ''}`)
            .set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send()
	}

	// TODO : Add pagination support
	static all(page = 0, search = null) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}spam_tests?page=${page}${search ? '&search=' + search : ''}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static get(id) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}spam_tests/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static getAutofix(internalId) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}spam_tests/autofix/${internalId}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static stats() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}spam_tests/stats`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static exportAddresses() {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}spam_tests/export.csv`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static get_blacklists(id) {
		return request
						.get(`${process.env['REACT_APP_BACKEND_URL']}spam_tests/${id}/blacklists`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`);
	}

	static update(id, type) {
		return request
						.post(`${process.env['REACT_APP_BACKEND_URL']}spam_tests/${id}`)
						.set('X-Api-Key', `Bearer ${localStorage.getItem('x-api-key')}`)
						.send({type: type});
	}
}

export default SpamCheckerService;
